// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ivanchai-js": () => import("./../../../src/pages/ivanchai.js" /* webpackChunkName: "component---src-pages-ivanchai-js" */),
  "component---src-pages-plants-js": () => import("./../../../src/pages/plants.js" /* webpackChunkName: "component---src-pages-plants-js" */),
  "component---src-pages-thoughts-js": () => import("./../../../src/pages/thoughts.js" /* webpackChunkName: "component---src-pages-thoughts-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

